import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

const base = 'terminal-cart-summary';
import { withRouter } from 'react-router-dom';

@inject('terminalCartStore')
@inject('terminalMenuStore')
class ItemAddRemove extends Component {
  render() {
    const { item, items, itemQuantity, toggleCombo, onClickIncrease, onClickDecrease, firstItem, history } =
      this.props;
    const itemName = item.title_bold;

    return (
      <div className={`${base}__item flex-align-items-center`} style={{ paddingTop: firstItem ? 30 : 0 }}>
        {item.type == 'combo' || item.type == 'meal' ?
          <>
            {item.type == "meal" && item.value_meal_menu_item.length > 0 ? 
            <div
              onClick={(e) => {
                if (item.type == 'combo') {
                  history.push(`/terminal-dealSelection?id=${item.unique_id}`);


                } else {
                  history.push(`/terminal-mealSelection?id=${item.id}&edit=true`);

                  this.props.terminalMenuStore.cartItem = item.today_menu_id;
                  this.props.terminalCartStore.getTotalPrice([item]);

                }
              }}
              style={{ width: '100%' }}
                className={`${base}__combo-item`}
            >
                <div className={`${base}__combo-tag`}>
                  COMBO
                </div>
                <div className='display--flex flex-align-items-center'>
                  <div style={{ position: 'relative' }} >

                    <p style={{ position: 'relative' }} className={`${base}__item-name text--bolder`}>
                      {item.today_menu_id.title_bold}

                    </p>
                  </div>



                </div> 



                <div>
                  {toggleCombo && item.value_meal_menu_item.length > 0
                    ? item.value_meal_menu_item.map((o, key) => {
                      return (
                        <div style={{ marginTop: '10px' }} key={key}>
                          <div
                            style={{
                              fontSize: '22px',
                              display: 'flex',
                              width: '100%',
                            }}
                          >
                            <span style={{ width: '90%' }}>
                              {o.value.title_bold}

                            </span>
                            <div
                              style={{
                                marginLeft: 'auto',
                              }}
                            >
                              x {o.quantity}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
                </div>
              </div>
              : <div
                onClick={(e) => {
                  if (item.type == 'combo') {
                    history.push(`/terminal-dealSelection?id=${item.unique_id}&edit=true`);
                  } else {
                    history.push(`/terminal-mealSelection?id=${item.id}&edit=true`);
                    this.props.terminalMenuStore.cartItem = item.today_menu_id;
                    this.props.terminalCartStore.getTotalPrice([item]);
                  }
                }}
                style={{ width: '100%', padding: '16px' }}
              >

                <p className={`${base}__item-name text--bolder`}>{item.type == "meal" ? item.today_menu_id.title_bold : item.today_menu_id.name}</p>


                <div>
                  {toggleCombo && item.value_meal_menu_item.length > 0
                    ? item.value_meal_menu_item.map((o, key) => {
                      return (
                        <div style={{ marginTop: '10px' }} key={key}>
                          <div
                            style={{
                              fontSize: '22px',
                              display: 'flex',
                              width: '100%',
                            }}
                          >
                            <span style={{ width: '80%' }}>
                            {o.value.title_bold}

                            </span>
                            <div
                              style={{
                                marginLeft: 'auto',
                                marginRight: '20px',
                              }}
                            >
                              x {o.quantity}
                            </div>
                          </div>
                        </div>
                      );
                    })
                    : null}
                </div>
              </div>}
            <div className={`${base}__increase-decrease-button-container`}>
              <button className={`${base}__increase mrs`} onClick={onClickDecrease.bind(null, item)}>
                <svg className="iconmoon-icon">
                  <use xlinkHref="#icon-minus" />
                </svg>
              </button>
              <div className={`${base}__quantity-box mrs`}>
                <div className={`${base}__quantity text--bolder`}>{item.quantity}</div>
              </div>
              <button className={`${base}__decrease`} onClick={onClickIncrease.bind(null, item)}>
                <svg className="iconmoon-icon">
                  <use xlinkHref="#icon-plus" />
                </svg>
              </button>
            </div>
          </>
          : (
            <>
              <div style={{width: '100%', padding: '16px'}}>
                <p className={`${base}__item-name text--bolder`}>{itemName}</p>
              </div>
              <div className={`${base}__increase-decrease-button-container`}>
                <button className={`${base}__increase mrs`} onClick={onClickDecrease.bind(null, item)}>
                  <svg className="iconmoon-icon">
                    <use xlinkHref="#icon-minus" />
                  </svg>
                </button>
                <div className={`${base}__quantity-box mrs`}>
                  <div className={`${base}__quantity text--bolder`}>{itemQuantity}</div>
                </div>
                <button className={`${base}__decrease`} onClick={onClickIncrease.bind(null, item)}>
                  <svg className="iconmoon-icon">
                    <use xlinkHref="#icon-plus" />
                  </svg>
                </button>
              </div>
            </>
          )}
      </div>
    );
  }
}

export default withRouter(ItemAddRemove);
