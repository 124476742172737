import React, { Component } from 'react';
const base = 'terminal-deal-combo';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';
import { uuid } from 'uuidv4';

@inject('terminalMenuStore')
class DealCombo extends Component {
    render() {
        const { terminalMenuStore, history } = this.props;
        const comboItems = terminalMenuStore.itemsFetcher.data.filter((o) => {
            return o.number_of_mains || o.number_of_drinks || o.number_of_addons;
        });

        return (
            <div style={{ padding: '10px' }} className={`${base}`}>
                <h1 className={`${base}__title-header`}>Super Saver Family Combo</h1>

                <div className={`${base}__main-wrapper`}>
                    {comboItems &&
                        comboItems.length > 0 &&
                        comboItems.map((o, key) => {
                            return (
                                <div
                                    key={key}
                                    onClick={(e) => {
                                        terminalMenuStore.comboObject = o;
                                        history.push(`/terminal-dealSelection?id=${uuid()}`);
                                    }}
                                    style={{
                                        backgroundImage: `url(${o.image})`,
                                    }}
                                    className={`${base}__card`}
                                ></div>
                            );
                        })}
                </div>
            </div>
        );
    }
}

export default withRouter(DealCombo);
